import { Svg } from "./Svg";

export const Logo = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        position: "absolute",
        top: "10px",
        left: "10px",
      }}
    >
      <Svg.VkLogo />
      <Svg.Education />
    </div>
  );
};
