import "./MainButton.css";

const MainButton = ({ title, onClick }) => {
  return (
    <button onClick={onClick} className="mainBtn">
      {title}
    </button>
  );
};

export default MainButton;
