import {Svg} from './Svg'

export const LogoEdu = () => {
	return (
		<div
			style={{
				position: 'absolute',
				top: '24px',
				left: '24px'
			}}
		>
			<Svg.LogoEdu/>
		</div>
	)
}
